import './style.css';

import { Typography, createMuiTheme } from '@material-ui/core';

import FactsPageBannerImage from './images/facts_page_banner.png';
import FaqsPageBannerImage from './images/faqs_page_banner.png';
import GodFathersLogo from './images/logo.svg';
import HomePageBannerImage from './images/home_page_banner.jpg';
import HomePageSectionImage from './images/home_page_section.jpg';
import PrivacyPolicyPDF from './Privacy_Policy.pdf';
import React from 'react';
import { getYear } from '../../src/util/getYear';

const headingFontFamily = `"Oswald Bold", Helvetica`;
const bodyFontFamily = `"Oswald", Helvetica`;
const buttonBorderRadius = 12;
const primaryColor = '#C32032';
const secondaryColor = '#FFC425';
const textShadow = `0px 4px 0px ${primaryColor}`;

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontWeightMedium: 600,
    useNextVariants: true,
    htmlFontSize: 16,
    h1: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: 1.2,
      letterSpacing: '-.9px',
      textTransform: 'uppercase',
      textShadow: '0px 4px 0px #C32032',
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '1.25px',
    },
    button: {
      ...defaultTheme.typography.button,
      fontFamily: headingFontFamily,
      fontSize: '16px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: buttonBorderRadius,
          borderTopRightRadius: buttonBorderRadius,
        },
        '&:last-child': {
          borderBottomLeftRadius: buttonBorderRadius,
          borderBottomRightRadius: buttonBorderRadius,
        },
      },
    },
  },
});

export default {
  id: 'godfather',
  name: `Godfather's`,
  logo: GodFathersLogo,
  styles: {
    muiTheme: {
      ...theme,
    },
    buttonBorderRadius: buttonBorderRadius,
  },
  search: {
    title: {
      label: 'FIND YOUR LOCAL PIZZA BOX RECYCLING GUIDELINES',
    }
  },
  appName: '',
  // meta tag values are used to generate the link preview when sharing link on social media
  metaTags: {
    title: `Godfather's Recycling`,
    description: `A recent study commissioned by WestRock, our primary box supplier and one of the largest box manufacturers in the U.S., estimated that 73% of the population has access to recycling programs for empty pizza boxes. Godfather's is partnering with many of the key players in the paper recycling industry to help increase the recycling of pizza boxes.`,
    url: 'https://recycling-godfathers.info',
    thumbnailImage: 'https://recycling.godfathers.info/link-preview.png',
  },
  footer: {
    text: `Copyright ${getYear()} Godfather's Pizza, Inc.`,
    privacyPolicyUrl: PrivacyPolicyPDF,
  },
  homePage: {
    bannerImage: HomePageBannerImage,
    headline: "It's true. Pizza boxes are recyclable!",
    body: (
      <Typography variant='body1' color='textPrimary'>
        Godfather’s Pizza is doing its part to make sure that pizza boxes work
        their way back into the recycling stream. We are working with leading
        companies within the manufacturing and recycling industries to make sure
        the true facts about pizza box recycling is heard and understood. A
        recent study by WestRock, our primary box supplier and one of the
        largest manufacturers in the U.S. estimated that 70% of the population
        has access to recycling programs within their local communities for used
        pizza boxes.
      </Typography>
    ),
    sectionImage: HomePageSectionImage,
    recyclingGuidelinesInstructions: 'Use our lookup tool at the top of the page to learn more about the recycling guidelines in your area.'
  },
  factsPage: {
    title: {
      style: { textShadow: textShadow },
    },
    banner: {
      bannerImage: FactsPageBannerImage,
      headline: 'Understanding the Facts.',
      description: `We’re making progress, but there’s still work to do. Here are some stats on pizza box recycling:`,
    },
    optionalFact: {
      headline: '50%',
      description:
        'The average corrugated box contains 50 percent recycled content.',
      source: 'The Fiber Box Association',
      sourceLink: '',
      icon: 'pizzabox',
    },
    optionalLongFact: null,
  },
  faqsPage: {
    title: {
      style: { textShadow: textShadow },
    },
    banner: {
      bannerImage: FaqsPageBannerImage,
    },
  },
  emailFeedback: {
    isSupported: false,
    privacy: null,
  },
};
